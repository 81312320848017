



























































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ErrorComponent from '../components/ErrorComponent.vue';
import ValidationSummaryComponent from '../components/ValidationSummaryComponent.vue';
import { createContact } from '../store/rest.service';
import { Person, PersonMeta } from '../store/models';

import { EmptyPerson } from '../store/models-empty';
import SpinnerComponent from '../components/SpinnerComponent.vue';

import * as _ from 'lodash';

@Component({
  components: {
    ErrorComponent,
    ValidationSummaryComponent,
    SpinnerComponent,
  },
})
export default class ContactComponent extends BaseComponent {

  @Prop() private orgId: any;

  private personSaving: boolean = false;
  private editedItem: Person = _.cloneDeep(EmptyPerson);
  private editedPersonId: string = '';
  private arrErrors: Error[] = [];
  private updateValidation: number = 0;

  protected mounted() {
      this.initPerson();
  }

  private initPerson() {
    this.editedItem = _.cloneDeep(EmptyPerson);
    const orgMeta = this.editedItem.person as PersonMeta;
    /*
    orgMeta.memberOf = this.orgId;
    orgMeta.contactFor = this.orgId;
    */
  }

  private formTitle(): string {
    return 'Add New Contact';
  }

  private closeContact() {
    this.$emit('close');
  }

  private save() {
    if (!this.personSaving) {
      if ((this.$refs.form1 as any).validate()) {
        this.updateValidation++;
        this.createContact();
      } else {
        this.updateValidation++;
      }
    }
  }

  private createContact() {
    this.personSaving = true;
    this.editedItem = this.removeCommonProperties(this.editedItem);
    createContact(this.editedItem).then((response) => {
      this.closeContact();
      this.$emit('save', response);
    }).catch((error) => {
      this.arrErrors.push(error);
    }).finally(() => {
      this.personSaving = false;
    });
  }
}
