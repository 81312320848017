



























































































































































































































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import BaseComponent from '../components/BaseComponent';
import PayeeComponent from '../components/PayeeComponent.vue';
import ContactComponent from '../components/ContactComponent.vue';
import ValidationSummaryComponent from '../components/ValidationSummaryComponent.vue';
import FormattedTextBoxComponent from '../components/FormattedTextBoxComponent.vue';
import DatePickerComponent from '../components/DatePickerComponent.vue';
import DialogComponent from '../components/DialogComponent.vue';
import { EmptyProject } from '../store/models-empty';
import ProjectHelper from '../components/ProjectHelper';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import { getProject, createProject, updateProject } from '../store/rest.service';
import { Project, ProjectMeta, CommonMeta, ImpactTargetsMeta, PurchaseOrderMeta } from '../store/models';
import * as _ from 'lodash';

@Component({
  components: {
    ValidationSummaryComponent,
    DatePickerComponent,
    PayeeComponent,
    FormattedTextBoxComponent,
    Treeselect,
    SpinnerComponent,
    DialogComponent,
    ContactComponent,
  },
})
export default class ProjectComponent extends BaseComponent {

  @Prop() private projectId: any;

  private helper: ProjectHelper = new ProjectHelper();
  private helperLoading: boolean = true;
  private helperContactsLoading: boolean = false;

  private extraErrors: Array<[string, string]> = [];

  private projectLoading: boolean = false;
  private projectSaving: boolean = false;
  private projectSaveResponse: any;

  private editedId: string = '';
  private editedItem: Project = _.cloneDeep(EmptyProject);
  private updateVendor: number = 0;
  private updateContact: number = 0;
  private bAddVendor: boolean = false;
  private bAddContact: boolean = false;
  private updateValidation: number = 0;
  private expandAdvanced: number = 1;

  protected mounted() {
    if (this.projectId !== '') {
      this.editProject(this.projectId);
    } else {
      this.newProject();
    }
  }

  @Watch('helper.isLoading')
  private onHelperChanged(val: any, oldVal: any) {
    this.helperLoading = this.helper.isLoading;
    if (!this.helperLoading) {
      this.setContractingTypeCategory();
    }
  }

  @Watch('helper.contactsLoading')
  private onHelperContactsChanged(val: any, oldVal: any) {
    this.helperContactsLoading = this.helper.contactsLoading;
  }

  private isLoading(): boolean {
    return this.projectLoading
    || this.helperLoading;
  }

  private getProject(id: string) {
    this.projectLoading = true;
    getProject(id, false).then((response) => {
      this.editedItem = response;
      // Upgrade the Project to the new data model - properties was moved.
      if (this.editedItem.project.properties) {
        this.editedItem.properties = _.cloneDeep(this.editedItem.project.properties);
        delete this.editedItem.project.properties;
      }
      this.setDefaultTargets();
      this.setContractingTypeCategory();
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.projectLoading = false;
    });
  }

  private updateEditedProject(id: string) {
    this.editedId = id;
    this.getProject(this.editedId);
    this.helper.populateDataLists(this.currentOrg);
  }

  private editProject(id: string) {
    this.updateEditedProject(id);
  }

  private newProject() {
    this.editedId = '';
    this.editedItem = _.cloneDeep(EmptyProject);
    this.setDefaultTargets();
    const project = (this.editedItem.project as ProjectMeta);
    project.hasSubmitter = this.currentUser.userinfo.identifier;
    project.ownerVendor = this.currentOrg;
    this.helper.populateDataLists(this.currentOrg);
  }

  private close() {
    if (this.editedId !== '') {
      this.updateEditedProject(this.editedId);
    }
    this.$emit('close');
  }

  private save() {
    if (!this.projectSaving) {
      // No validation for Treeselect control.
      const validate: boolean = (this.$refs.form as any).validate();
      this.extraErrors = [];
      // Special case for validating Discipline as this is not a Vuetify control.
      if ((this.editedItem.project as ProjectMeta).disciplineCategory === undefined) {
        const treeDiv = document.getElementsByClassName('vue-treeselect')[0];
        treeDiv.classList.add('vue-treeselect-required');
        this.extraErrors.push(['Discipline', 'Required']);
        this.updateValidation++;
      } else if (validate) {
        // Set the Contracting Type using the disciplineCategory temporary property.
        const projectMeta = this.editedItem.project as ProjectMeta;
        if (projectMeta.disciplineCategory !== undefined) {
          const discipline = (projectMeta.disciplineCategory as string).split(';');
          this.editedItem.properties.discipline = discipline[1];
        }
        this.updateValidation++;
        this.editedId === '' ? this.createProject() : this.updateProject();
      } else {
        this.updateValidation++;
      }
    }
  }

  private createProject() {
    this.projectSaving = true;
    const projectToSave: Project = this.removeCommonProperties(_.cloneDeep(this.editedItem));
    (projectToSave.purchaseOrder as PurchaseOrderMeta).poDate = this.editedItem.project.startDate;
    delete projectToSave.project.disciplineCategory;
    createProject(projectToSave).then((response) => {
      this.projectSaveResponse = response;
      this.projectSaving = false;
      this.$emit('save');
      this.close();
    }).catch((error) => {
      this.projectSaving = false;
      this.close();
      this.$emit('error', error);
    });
  }

  private updateProject() {
    this.projectSaving = true;
    const projectToSave = this.removeCommonProperties(_.cloneDeep(this.editedItem));
    delete projectToSave.project.disciplineCategory;
    (this.editedItem.common as CommonMeta).recordType = ['Project'];
    updateProject(this.editedId, projectToSave).then((response) => {
      this.projectSaveResponse = response;
      this.projectSaving = false;
      this.close();
      this.$emit('save');
    }).catch((error) => {
      this.$emit('error', error);
      this.projectSaving = false;
      this.close();
    });
  }

  private onContractingTypeChanged(node, instanceId) {
    const treeDiv = document.getElementsByClassName('vue-treeselect')[0];
    treeDiv.classList.remove('vue-treeselect-required');
  }

  private setDefaultTargets() {
    if (this.editedItem.project.impactTargets === undefined) {
      this.editedItem.project.impactTargets = {
        socialProcurement: 0,
        indigenousProjectBenefit: 0,
        diverseProjectBenefit: 0,
        indigenousCommunityContribution: 0,
        diverseCommunityContribution: 0,
        indigenousEmployment: 0,
        diverseEmployment: 0,
      };
    }
    const impactTargets: ImpactTargetsMeta = this.editedItem.project.impactTargets as ImpactTargetsMeta;
    if (!impactTargets.indigenousProjectBenefit) {
      impactTargets.indigenousProjectBenefit = 0;
    }
    if (!impactTargets.indigenousCommunityContribution) {
      impactTargets.indigenousCommunityContribution = 0;
    }
    if (!impactTargets.indigenousEmployment) {
      impactTargets.indigenousEmployment = 0;
    }
    if (!impactTargets.diverseProjectBenefit) {
      impactTargets.diverseProjectBenefit = 0;
    }
    if (!impactTargets.diverseEmployment) {
      impactTargets.diverseEmployment = 0;
    }
    if (!impactTargets.socialProcurement) {
      impactTargets.socialProcurement = 0;
    }
  }

  private setContractingTypeCategory() {
    if (this.editedItem && this.helper.contractingTypes) {
      for (const contractingType of this.helper.contractingTypes.refData.values) {
        if (contractingType.code === this.editedItem.properties.discipline) {
          (this.editedItem.project as ProjectMeta).disciplineCategory = contractingType.categoryCode + ';' + contractingType.code;
        }
      }
    }
  }

  private addVendor() {
    this.bAddVendor = true;
  }

  private addContact() {
    this.bAddContact = true;
  }

  private saveVendor(id) {
    this.updateVendor++;
    this.helper.getVendors(this.currentOrg, true);
    // Set the client to be the newly created Vendor.
    if (this.editedItem.project) {
      this.editedItem.project.creatorVendor = id;
    }
  }

  // Set the newly created contact to be the partner contact.
  private saveContact(id) {
    this.helper.getContacts();
    if (!this.editedItem.project.hasContact) {
      this.editedItem.project.hasContact = [];
    }
    (this.editedItem.project.hasContact as string[]).push(id);
    this.updateContact++;
  }
}
